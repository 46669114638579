<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 21:08:06
 * @LastEditors: Zhongyu
 * @LastEditTime: 2022-01-17 14:31:16
-->
<template>
  <div class="install-details">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{
          path:
            type == 6
              ? '/personal-center/install/evaluation'
              : list[type - 1].path
        }"
      >
        订单数据统计
      </el-breadcrumb-item>
      <el-breadcrumb-item
        ><span @click="show_accessories = false"
          >订单详情</span
        ></el-breadcrumb-item
      >
      <el-breadcrumb-item v-if="show_accessories"
        >配件申请详情</el-breadcrumb-item
      >
    </el-breadcrumb>

    <div class="order_details" v-if="!show_accessories">
      <div class="top" v-if="isReoder != '1'">
        <div class="top-left">
          <div class="top-left-no">订单号：{{ data.orderNum }}</div>
          <div class="top-left-type">
            {{ list[data.status - 2] ? list[data.status - 2].title : "已取消" }}
          </div>
          <div class="top-left-time">
            安装时间：{{ data.serviceTime.split(" ")[0] }}
          </div>
          <div class="top-left-operation-column" v-show="data.status == 3">
            <el-button type="warning" @click="handleAppointment">
              预约时间
            </el-button>
            <el-button type="danger" @click="handleCancel">
              取消订单
            </el-button>
          </div>
          <div class="top-left-operation-column" v-show="data.status == 2">
            <el-button type="success" @click="openTipModal"> 签收 </el-button>
            <el-button type="danger" @click="handleRefuse"> 拒收 </el-button>
          </div>
        </div>
        <div class="top-right">
          <div class="right-item" v-for="(item, i) in list" :key="i">
            <div class="item">
              <img
                :src="i < Number(data.status) - 1 ? item.src_sel : item.src"
              />
              <div
                class="type"
                :class="{ arrive: i < Number(data.status) - 1 }"
              >
                {{ item.title }}
              </div>
              <div
                class="time"
                v-show="i < Number(data.status) - 1 && i == 0"
              ></div>
              <div class="time" v-show="i < Number(data.status) - 1 && i == 1">
                {{ data.receivingTime }}
              </div>
              <div class="time" v-show="i < Number(data.status) - 1 && i == 2">
                {{ data.appointmentDate }}
                {{ data.appointmentTimeS }} 至 {{ data.appointmentTimeE }}
              </div>
              <div class="time" v-show="i < Number(data.status) - 1 && i == 3">
                {{ data.completeTime }}
              </div>
              <div class="time" v-show="i < Number(data.status) - 1 && i == 4">
                {{ data.auditTime }}
              </div>
              <div class="time" v-show="i >= Number(data.status) - 1"></div>
            </div>
            <div class="arrows" v-if="i < 4">
              <img
                :src="
                  i + 1 < Number(data.status) - 1
                    ? item.arrows_sel
                    : item.arrows
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="install-body">
          <div class="install-body-list" v-show="data.status <= 2">
            <table>
              <thead>
                <tr class="list-header">
                  <th class="header-item item-310">产品信息</th>
                  <th class="header-item item-360">客户信息</th>
                  <th class="header-item item-215">订单类型</th>
                  <th class="header-item item-255">结算收益</th>
                </tr>
              </thead>
            </table>
            <table>
              <tbody class="list-line-item" v-for="i in 1" :key="i">
                <tr class="sep-row">
                  <td colspan="4"></td>
                </tr>
                <tr>
                  <td colspan="4" class="line-item-colspan">
                    <span class="order-number">
                      订单号：{{ data.orderNum }}
                    </span>
                    <span class="time">
                      安装时间：{{ data.serviceTime.split(" ")[0] }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="line-item item-310">
                    <div class="img-or-name">
                      <img :src="$utils.picturePrefix(data.productImage)" />
                      <span>
                        {{ data.productName }}
                      </span>
                    </div>
                  </td>
                  <td class="line-item item-360">
                    <div class="real-name">
                      {{ $utils.formattedName(data.clientName) }}
                    </div>
                    <div class="address">
                      {{ data.clientAddress }}********
                      <p>****</p>
                    </div>
                    <div class="phone">
                      {{ $utils.formattedPhone(data.clientPhone) }}
                    </div>
                  </td>
                  <td class="line-item item-215">
                    <div class="order-type">
                      {{ $utils.orderTypeName(data.type) }}
                    </div>
                  </td>
                  <td class="line-item item-255">
                    <div class="order-money">
                      基础金额：￥{{ $utils.formatMoney(data.money) }}
                    </div>
                    <div class="order-money">
                      激励金额：￥{{ $utils.formatMoney(data.incentiveMoney) }}
                    </div>
                    <div class="order-money">
                      追加金额：￥{{ $utils.formatMoney(data.additionalMoney) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="install-body-list" v-show="data.status > 2">
            <table>
              <thead>
                <tr class="list-header">
                  <th class="header-item item-310">产品信息</th>
                  <th class="header-item item-300">客户信息</th>
                  <th class="header-item item-145">订单类型</th>
                  <th class="header-item item-175">结算收益</th>
                  <th class="header-item item-210" v-if="data.status == 3">
                    签收时间
                  </th>
                  <th class="header-item item-210" v-if="data.status == 4">
                    预约时间
                  </th>
                  <th class="header-item item-210" v-if="data.status == 5">
                    完成时间
                  </th>
                  <th class="header-item item-210" v-if="data.status == 6">
                    结算时间
                  </th>
                </tr>
              </thead>
            </table>
            <tbody class="list-line-item">
              <tr class="sep-row">
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="5" class="line-item-colspan">
                  <span class="order-number">
                    订单号：{{ data.orderNum }}
                  </span>
                  <span class="time">
                    安装时间：{{ data.serviceTime.split(" ")[0] }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="line-item item-310">
                  <div class="img-or-name">
                    <img :src="$utils.picturePrefix(data.productImage)" />
                    <span>
                      {{ data.productName }}
                    </span>
                  </div>
                </td>
                <td class="line-item item-300">
                  <div class="real-name">
                    {{ data.clientName }}
                  </div>
                  <div class="address">
                    <p style="margin-bottom: 5px">
                      客户地址：{{ data.clientAddress }}
                    </p>
                    <p>详细地址：{{ data.clientAddressDetail }}</p>
                  </div>
                  <div class="phone">
                    {{ data.clientPhone }}
                  </div>
                </td>
                <td class="line-item item-145">
                  <div class="order-type">
                    {{ $utils.orderTypeName(data.type) }}
                  </div>
                </td>
                <td class="line-item item-175">
                  <div class="order-money">
                    基础金额：￥{{ $utils.formatMoney(data.money) }}
                  </div>
                  <div class="order-money">
                    激励金额：￥{{ $utils.formatMoney(data.incentiveMoney) }}
                  </div>
                  <div class="order-money">
                    追加金额：￥{{ $utils.formatMoney(data.additionalMoney) }}
                  </div>
                  <div class="order-money" v-if="data.status == 6">
                    评价金额：￥{{ $utils.formatMoney(data.giveMoney) }}
                  </div>
                </td>
                <td class="line-item item-210" v-if="data.status == 3">
                  <div class="make-date">{{ data.receivingTime }}</div>
                </td>
                <td class="line-item item-210" v-if="data.status == 4">
                  <div class="make-date">{{ data.appointmentDate }}</div>
                  <div class="make-time">{{ data.appointmentTimeS }}</div>
                  <div class="make-time">至</div>
                  <div class="make-time">{{ data.appointmentTimeE }}</div>
                </td>
                <td class="line-item item-210" v-if="data.status == 5">
                  <div class="make-date">{{ data.completeTime }}</div>
                </td>
                <td class="line-item item-210" v-if="data.status == 6">
                  <div class="make-date">{{ data.auditTime }}</div>
                </td>
              </tr>
            </tbody>
          </div>

          <div style="margin: 10px 0">
            <span style="font-size: 18px">备注：</span>{{ data.remarke }}
          </div>
          <MyDialog
            :logisticsNum="data.logisticsNum"
            :logisticsName="data.logisticsName"
            :client-phone="data.clientPhone"
          ></MyDialog>
          <div class="technician">
            <div class="technician_title">技术支持</div>
            <div style="display: flex;flex-wrap: wrap;">
              <div
                class="staff"
                v-for="(item, index) in data.technologyList"
                :key="index"
              >
                <p class="name">{{ item.name }}</p>
                <p class="phone">{{ item.phone }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="message" v-if="isReoder != '1'">
        <div class="title">安装单留言</div>
        <div class="message-list">
          <!-- 普通留言 -->
          <div label="普通留言" name="1">
            <div class="message-operation">
              <div class="operation-item">
                <el-avatar
                  :size="60"
                  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                ></el-avatar>
                <el-input
                  class="form-textarea"
                  type="textarea"
                  :rows="5"
                  v-model="mInfo"
                  placeholder="发布留言"
                ></el-input>
              </div>
              <div class="operation-btn">
                <el-button
                  type="primary"
                  @click="installOrderComment(0, 1, mInfo)"
                  >留言</el-button
                >
              </div>
            </div>
            <ul
              class="message-ul"
              v-show="
                data.orderCommentsList1 && data.orderCommentsList1.length > 0
              "
            >
              <li
                class="message-li"
                v-for="(item, i) in data.orderCommentsList1"
                :key="i"
              >
                <div class="message-body">
                  <el-avatar :size="60" :src="item.photo"></el-avatar>
                  <div style="width: 980px">
                    <div class="name">{{ item.customerName }}</div>
                    <div class="info">
                      {{ item.info }}
                    </div>
                    <div class="operation">
                      <div v-show="userId != item.customerId">
                        <div
                          v-show="!messageId || messageId != item.id"
                          @click="
                            messageId = item.id;
                            replyInfo = '';
                          "
                        >
                          <img src="@/assets/img/common/img-message-icon.png" />
                          回复
                        </div>
                        <div
                          v-show="messageId && messageId == item.id"
                          @click="messageId = null"
                        >
                          <img src="@/assets/img/common/img-message-icon.png" />
                          取消回复
                        </div>
                      </div>
                      <div></div>
                      <div class="time">{{ item.createDate }}</div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="messageId == item.id"
                  class="message-operation"
                  style="padding-left: 70px; padding-top: 25px"
                >
                  <div class="operation-item">
                    <el-input
                      class="form-textarea"
                      type="textarea"
                      :rows="5"
                      v-model="replyInfo"
                      placeholder="请输入回复"
                    ></el-input>
                  </div>
                  <div class="operation-btn">
                    <el-button
                      type="primary"
                      @click="installOrderComment(item.id, 1, replyInfo)"
                      >回复</el-button
                    >
                  </div>
                </div>
                <template v-if="item.orderCommentsList">
                  <ol
                    class="message-ol"
                    v-for="(jtem, j) in item.orderCommentsList"
                    :key="j"
                  >
                    <div class="message-body">
                      <el-avatar :size="40" :src="jtem.photo"></el-avatar>
                      <div style="width: 930px">
                        <div class="name">{{ jtem.customerName }}</div>
                        <div class="info">{{ jtem.info }}</div>
                        <div class="operation">
                          <div v-show="userId != jtem.customerId">
                            <div
                              v-show="!messageId || messageId != jtem.id"
                              @click="
                                messageId = jtem.id;
                                replyInfo = '';
                              "
                            >
                              <img
                                src="@/assets/img/common/img-message-icon.png"
                              />
                              回复
                            </div>
                            <div
                              v-show="messageId && messageId == jtem.id"
                              @click="messageId = null"
                            >
                              <img
                                src="@/assets/img/common/img-message-icon.png"
                              />
                              取消回复
                            </div>
                          </div>
                          <div></div>
                          <div class="time">{{ jtem.createDate }}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-show="messageId == jtem.id"
                      class="message-operation"
                      style="padding-left: 70px; padding-top: 25px"
                    >
                      <div class="operation-item">
                        <el-input
                          class="form-textarea"
                          type="textarea"
                          :rows="5"
                          v-model="replyInfo"
                          placeholder="请输入回复"
                        ></el-input>
                      </div>
                      <div class="operation-btn">
                        <el-button
                          type="primary"
                          @click="installOrderComment(jtem.id, 1, replyInfo)"
                        >
                          回复
                        </el-button>
                      </div>
                    </div>
                  </ol>
                </template>
              </li>
            </ul>
          </div>
          <!-- 配件留言 -->
          <!-- <el-tab-pane label="配件留言" name="2">
            <div class="message-operation">
              <el-form
                ref="installOrderMassage"
                :inline="true"
                :model="accessories"
                :rules="rules"
              >
                <el-form-item
                  label="配件名称"
                  label-width="80px"
                  style="width: 400px;"
                  prop="accessoriesName"
                >
                  <el-input
                    v-model="accessories.accessoriesName"
                    placeholder="请输入配件名称"
                    style="width: 250px;"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="配件数量"
                  label-width="80px"
                  style="width: 400px;"
                  prop="accessoriesNum"
                >
                  <el-input
                    type="number"
                    v-model="accessories.accessoriesNum"
                    placeholder="请输入配件数量"
                    style="width: 250px;"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="operation-item">
                <el-avatar
                  :size="60"
                  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                ></el-avatar>
                <el-input
                  class="form-textarea"
                  type="textarea"
                  :rows="5"
                  v-model="mInfo"
                  placeholder="发布留言"
                ></el-input>
              </div>
              <div class="operation-btn">
                <el-button
                  type="primary"
                  @click="installOrderMassage(0, 2, mInfo)"
                >
                  留言
                </el-button>
              </div>
            </div>
            <ul
              class="message-ul"
              v-show="
                data.orderCommentsList2 && data.orderCommentsList2.length > 0
              "
            >
              <li
                class="message-li"
                v-for="(item, i) in data.orderCommentsList2"
                :key="i"
              >
                <div class="message-body">
                  <el-avatar :size="60" :src="item.photo"></el-avatar>
                  <div style="width: 980px;">
                    <div class="name">{{ item.customerName }}</div>
                    <div class="info">
                      <span style="margin-right: 30px;"
                        >配件名称：{{ item.accessoriesName }}</span
                      >
                      <span style="margin-right: 30px;"
                        >配件数量：{{ item.accessoriesNum }}</span
                      >
                      {{ item.info }}
                    </div>
                    <div class="operation">
                      <div v-show="userId != item.customerId">
                        <div
                          v-show="!messageId || messageId != item.id"
                          @click="
                            messageId = item.id;
                            replyInfo = '';
                          "
                        >
                          <img src="@/assets/img/common/img-message-icon.png" />
                          回复
                        </div>
                        <div
                          v-show="messageId && messageId == item.id"
                          @click="messageId = null"
                        >
                          <img src="@/assets/img/common/img-message-icon.png" />
                          取消回复
                        </div>
                      </div>
                      <div></div>
                      <div class="time">{{ item.createDate }}</div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="messageId == item.id"
                  class="message-operation"
                  style="padding-left: 70px;padding-top: 25px;"
                >
                  <div class="operation-item">
                    <el-input
                      class="form-textarea"
                      type="textarea"
                      :rows="5"
                      v-model="replyInfo"
                      placeholder="请输入回复"
                    ></el-input>
                  </div>
                  <div class="operation-btn">
                    <el-button
                      type="primary"
                      @click="installOrderComment(item.id, 2, replyInfo)"
                    >
                      回复
                    </el-button>
                  </div>
                </div>
                <template v-if="item.orderCommentsList">
                  <ol
                    class="message-ol"
                    v-for="(jtem, j) in item.orderCommentsList"
                    :key="j"
                  >
                    <div class="message-body">
                      <el-avatar :size="40" :src="jtem.photo"></el-avatar>
                      <div style="width: 930px;">
                        <div class="name">{{ jtem.customerName }}</div>
                        <div class="info">
                          {{ jtem.info }}
                        </div>
                        <div class="operation">
                          <div v-show="userId != jtem.customerId">
                            <div
                              v-show="!messageId || messageId != jtem.id"
                              @click="
                                messageId = jtem.id;
                                replyInfo = '';
                              "
                            >
                              <img
                                src="@/assets/img/common/img-message-icon.png"
                              />
                              回复
                            </div>
                            <div
                              v-show="messageId && messageId == jtem.id"
                              @click="messageId = null"
                            >
                              <img
                                src="@/assets/img/common/img-message-icon.png"
                              />
                              取消回复
                            </div>
                          </div>
                          <div></div>
                          <div class="time">{{ item.createDate }}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-show="messageId == jtem.id"
                      class="message-operation"
                      style="padding-left: 70px;padding-top: 25px;"
                    >
                      <div class="operation-item">
                        <el-input
                          class="form-textarea"
                          type="textarea"
                          :rows="5"
                          v-model="replyInfo"
                          placeholder="请输入回复"
                        ></el-input>
                      </div>
                      <div class="operation-btn">
                        <el-button
                          type="primary"
                          @click="installOrderComment(jtem.id, 2, replyInfo)"
                        >
                          回复
                        </el-button>
                      </div>
                    </div>
                  </ol>
                </template>
              </li>
            </ul>
          </el-tab-pane> -->
          <!-- </el-tabs> -->
        </div>
      </div>
      <div class="message" v-else>
        <div class="title">返单原因</div>
        <div class="message-list">
          <div label="普通留言" name="1">
            <div class="message-operation">
              <div class="operation-item">
                <el-avatar
                  :size="60"
                  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                ></el-avatar>
                <el-input
                  disabled
                  class="form-textarea"
                  type="textarea"
                  :rows="5"
                  v-model="backWhy"
                  placeholder="发布留言"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="evaluation" v-if="data.status > 3">
        <!-- 配件留言 -->
        <el-tabs
          v-model="tabType"
          @tab-click="
            mInfo = '';
            replyInfo = '';
          "
        >
          <el-tab-pane
            class="explain"
            label="配件单申请"
            name="1"
            v-if="data.status == 4"
          >
            <div>
              <el-form
                ref="installOrderMassage"
                :inline="true"
                :model="accessories"
                :rules="rules"
                label-position="left"
                label-width="90px"
              >
                <el-form-item
                  label="配件名称"
                  style="width: 400px"
                  prop="accessoriesName"
                >
                  <el-input
                    v-model="accessories.accessoriesName"
                    placeholder="请输入配件名称"
                    style="width: 250px"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  required
                  label="配件数量"
                  style="width: 400px"
                  prop="accessoriesNum"
                >
                  <el-input
                    type="number"
                    v-model="accessories.accessoriesNum"
                    placeholder="请输入配件数量"
                    style="width: 250px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="申请原因" style="width: 100%">
                  <el-input
                    class="cause"
                    type="textarea"
                    resize="none"
                    v-model="accessories.accessoriesWhy"
                    placeholder="填写备注原因"
                    style="width: 660px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="上传凭证" prop="accessoriesImg">
                  <!--   style="width: 400px" -->
                  <!-- <el-input
                  class="cause"
                  type="textarea"
                  resize
                  placeholder="填写原因"
                  style="width: 300px"
                ></el-input> -->
                  <!--  :file-list="kwForm.imageUrl" -->
                  <el-upload
                    action="*"
                    :http-request="uploadImg"
                    list-type="picture-card"
                    :on-remove="handleAvatarRemove"
                    :before-upload="beforeAvatarUpload"
                    accept="image/*"
                    :file-list="filelist"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <!-- <img width="100%" :src="dialogImageUrl" alt="" /> -->
                    <!-- @click="installOrderMassage(0, 2, mInfo)" -->
                  </el-dialog>
                </el-form-item>
              </el-form>
              <div class="button">
                <el-button type="primary" @click="accessories_save()">
                  提交
                </el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane class="explain" label="申请列表" name="2">
            <!-- <div class="mountings"> -->
            <el-table
              :data="details_list"
              @cell-click="cellClick"
              style="width: 100%"
              height="467"
            >
              <el-table-column
                align="center"
                prop="accessoriesName"
                label="配件名称"
                width="width"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="accessoriesNum"
                label="配件数量"
                width="width"
              >
              </el-table-column>
              <el-table-column
                align="center"
                show-overflow-tooltip
                prop="accessoriesWhy"
                label="申请原因"
                width="width"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="oldReturn"
                label="是否返件"
                width="width"
              >
                <template v-slot="{ row }">
                  <span
                    style="color: #f56c6c"
                    v-if="row.oldReturn == 1 && row.status == 2"
                    >是(需要处理)</span
                  >
                  <span v-else-if="row.oldReturn == 0 && row.status == 2"
                    >否</span
                  >
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="status"
                label="审核状态"
                width="width"
              >
                <template v-slot="{ row }">
                  <span
                    :style="{ color: status_color[row.status] }"
                    style="cursor: pointer;"
                    >{{ status[row.status] }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="title">
                <div v-for="(item, i) in title_list" :key="i">{{ item }}</div>
              </div>
              <div
                class="details"
                v-for="(item, index) in details_list"
                :key="index"
              >
                <div @click="mountings_status(index)">
                  {{ item.accessoriesName  }}
                </div>
                <div>
                  {{ item.accessoriesNum }}
                </div>
                <div>
                  {{ item.accessoriesWhy }}
                </div>
                <div>
                  {{ item.status }}
                </div>
              </div>
            </div> -->
            <!-- <div class="message-operation">
            <el-form
              ref="installOrderMassage"
              :inline="true"
              :model="accessories"
              :rules="rules"
            >
              <el-form-item
                label="配件名称"
                label-width="80px"
                style="width: 400px;"
                prop="accessoriesName"
              >
                <el-input
                  v-model="accessories.accessoriesName"
                  placeholder="请输入配件名称"
                  style="width: 250px;"
                ></el-input>
              </el-form-item>
              <el-form-item
                required
                label="配件数量"
                label-width="80px"
                style="width: 400px;"
                prop="accessoriesNum"
              >
                <el-input
                  type="number"
                  v-model="accessories.accessoriesNum"
                  placeholder="请输入配件数量"
                  style="width: 250px;"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="operation-item">
              <el-avatar
                :size="60"
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              ></el-avatar>
              <el-input
                class="form-textarea"
                type="textarea"
                :rows="5"
                v-model="mInfo"
                placeholder="发布留言"
              ></el-input>
            </div>
            <div class="operation-btn">
              <el-button
                type="primary"
                @click="installOrderMassage(0, 2, mInfo)"
              >
                留言
              </el-button>
            </div>
          </div>
          <ul
            class="message-ul"
            v-show="
              data.orderCommentsList2 && data.orderCommentsList2.length > 0
            "
          >
            <li
              class="message-li"
              v-for="(item, i) in data.orderCommentsList2"
              :key="i"
            >
              <div
                v-show="messageId == item.id"
                class="message-operation"
                style="padding-left: 70px;padding-top: 25px;"
              >
                <div class="operation-item">
                  <el-input
                    class="form-textarea"
                    type="textarea"
                    :rows="5"
                    v-model="replyInfo"
                    placeholder="请输入回复"
                  ></el-input>
                </div>
                <div class="operation-btn">
                  <el-button
                    type="primary"
                    @click="installOrderComment(item.id, 2, replyInfo)"
                  >
                    回复
                  </el-button>
                </div>
              </div>
              <template v-if="item.orderCommentsList">
                <ol
                  class="message-ol"
                  v-for="(jtem, j) in item.orderCommentsList"
                  :key="j"
                >
                  <div class="message-body">
                    <el-avatar :size="40" :src="jtem.photo"></el-avatar>
                    <div style="width: 930px;">
                      <div class="name">{{ jtem.customerName }}</div>
                      <div class="info">
                        {{ jtem.info }}
                      </div>
                      <div class="operation">
                        <div v-show="userId != jtem.customerId">
                          <div
                            v-show="!messageId || messageId != jtem.id"
                            @click="
                              messageId = jtem.id;
                              replyInfo = '';
                            "
                          >
                            <img
                              src="@/assets/img/common/img-message-icon.png"
                            />
                            回复
                          </div>
                          <div
                            v-show="messageId && messageId == jtem.id"
                            @click="messageId = null"
                          >
                            <img
                              src="@/assets/img/common/img-message-icon.png"
                            />
                            取消回复
                          </div>
                        </div>
                        <div></div>
                        <div class="time">{{ item.createDate }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="messageId == jtem.id"
                    class="message-operation"
                    style="padding-left: 70px;padding-top: 25px;"
                  >
                    <div class="operation-item">
                      <el-input
                        class="form-textarea"
                        type="textarea"
                        :rows="5"
                        v-model="replyInfo"
                        placeholder="请输入回复"
                      ></el-input>
                    </div>
                    <div class="operation-btn">
                      <el-button
                        type="primary"
                        @click="installOrderComment(jtem.id, 2, replyInfo)"
                      >
                        回复
                      </el-button>
                    </div>
                  </div>
                </ol>
              </template>
            </li>
          </ul> -->

            <!-- <div class="rate">
            <div class="left">
              <div class="left_top">
                <img
                  class="rate_head"
                  src="~@/assets/img/common/img-common-carousel.png"
                  alt=""
                />
                <p>客戶</p>
                <p>xxxxxxxx</p>
              </div>
              <el-rate v-model="value" show-text> </el-rate>
            </div>
            <div class="rate_date">2012-02-05</div>
          </div> -->
          </el-tab-pane>
        </el-tabs>

        <!-- <div
        class="message-body"
        v-if="
          data.installationEvaluation != null &&
            data.installationEvaluation != ''
        "
      > -->
        <!-- <el-avatar
          :size="60"
          src="http://49.234.124.108:8080/water_purification/opt/upload/img/1631850282040935786.png"
        ></el-avatar> -->
        <!-- <div style="width: 980px;">
          <div class="name">客户</div>
          <div class="info">
            {{ data.evaluation }}
          </div>
          <div class="operation">
            <el-rate
              :value="data.installationEvaluation"
              disabled
              text-color="rgba(250, 200, 29, 1)"
              show-text
              :colors="[
                'rgba(250, 200, 29, 1)',
                'rgba(250, 200, 29, 1)',
                'rgba(250, 200, 29, 1)'
              ]"
              :texts="['极差~', '失望~', '一般~', '满意~', '很满意~']"
            >
            </el-rate>
            <div class="time">{{ data.evaluationTime }}</div>
          </div>
        </div>
      </div> -->
        <!-- <div class="head">
        <div class="portrait">2</div>
        <div class="leave"></div>
      </div>
      <el-button type="primary" class="btn">留言</el-button>
      <div>3</div> -->

        <!-- <empty v-else description="暂无评价~"></empty> -->
      </div>
      <div>
        <!-- <div class="information">
          <div class="rise">返件信息</div>
          <div>
            <p class="space">派件地址：</p>
            <el-input
              v-model="accessories.accessoriesName"
              placeholder=""
              style="width: 250px;"
            ></el-input>
            <p class="space">派件要求：</p>
            <el-input
              class="cause"
              type="textarea"
              maxlength="200"
              show-word-limit
              v-model="accessories.accessoriesNum"
              placeholder=""
              style="width: 660px;"
            ></el-input>
            <div class="frame"></div>
          </div>
          <div class="logistics">
            <div>
              <el-form ref="form" :model="form" class="header">
                <el-form-item label="物流公司:">
                  <el-input></el-input>
                </el-form-item>
                <el-form-item label="物流单号:">
                  <el-input></el-input>
                </el-form-item>
                <el-form-item label="第三方配件单号:">
                  <el-input></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="remarks">发件备注:</div>
            <el-input
              class="text"
              type="textarea"
              v-model="mInfo"
              placeholder="发布留言"
            >
            </el-input>
            <div class="remarks">凭证:</div>
            <img
              src="~@/assets/img/common/img-common-carousel.png"
              alt=""
              style="width:120px;height:120px;border-radius:10px;margin-right: 20px;"
            />
            <img
              src="~@/assets/img/common/img-common-carousel.png"
              alt=""
              style="width:120px;height:120px;border-radius:10px;margin-right: 20px;"
            />
            <div class="button_btn">
              <el-button type="" @click="installOrderMassage(0, 2, mInfo)">
                提交
              </el-button>
            </div>
          </div>
        </div> -->
        <!-- 已完成的订单展示凭证 -->
        <!-- <div class="evaluate" v-if="data.status == 6">
          <div class="title">我的凭证：</div>
          <img
            v-for="(item, i) in data.auditFile"
            :key="i"
            :src="$utils.picturePrefix(item)"
            alt=""
            width="120"
            height="120"
            style="margin-left: 10px"
          />
        </div> -->
        <div class="evaluate" v-show="evaluate.evaluationTime">
          <div class="title">安装单评价</div>
          <div class="rate">
            <div class="left">
              <div class="left_top">
                <img
                  class="rate_head"
                  src="~@/assets/img/home/img-user.png"
                  alt=""
                />
                <p></p>
                <p>{{ evaluate.evaluation }}</p>
              </div>
              <el-rate
                v-model="evaluate.installationEvaluation"
                show-text
                disabled
              ></el-rate>
            </div>
            <div class="rate_date">{{ evaluate.evaluationTime }}</div>
          </div>
        </div>
      </div>
      <div class="message" v-if="data.status == 4">
        <div class="title">完成订单</div>
        <el-form
          class="logistics_form"
          ref="logistics_form"
          inline
          label-position="left"
          label-width="120px"
          :model="finishData"
          :rules="finishRules"
        >
          <el-form-item
            label="我的凭证:"
            prop="auditFile"
            class="finish-audit-file"
          >
            <span class="files">
              <div
                v-for="(item, i) in finishData.auditFile"
                :key="i"
                style="position: relative;"
              >
                <img :src="item.showPath" v-show="item.type == 1" />
                <video
                  :src="item.showPath"
                  v-show="item.type == 2"
                  controls="controls"
                />
                <!-- v-if="item.showPath.indexOf("")" -->
                <span
                  class="upload-list__item-actions"
                  v-show="finishData.auditFile.length > 0"
                >
                  <i
                    class="el-icon-delete"
                    @click="handleRemoveFinishFile(i)"
                  ></i>
                </span>
              </div>
              <el-upload
                action="*"
                :http-request="uploadImgFinishFile"
                list-type="picture-card"
                :on-remove="handleRemoveFinishFile"
                :before-upload="beforeAvatarUpload"
                accept="image/*,video/*"
                :file-list="finishFilelist"
                :show-file-list="false"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </span>
          </el-form-item>
        </el-form>

        <div class="button">
          <el-button type="primary" @click="installationOrderComplete()">
            完成
          </el-button>
        </div>
      </div>
      <div class="message" v-if="data.status == 5 || data.status == 6">
        <div class="title">完成订单</div>
        <el-form
          class="logistics_form"
          ref="logistics_form"
          inline
          label-position="left"
          label-width="120px"
        >
          <el-form-item label="我的凭证:" class="finish-audit-file">
            <span class="files">
              <div
                v-for="(item, i) in finishData.auditFile"
                :key="i"
                style="position: relative;"
              >
                <img :src="item.showPath" v-show="item.type == 1" />
                <video
                  :src="item.showPath"
                  v-show="item.type == 2"
                  controls="controls"
                />
              </div>
            </span>
          </el-form-item>
        </el-form>
      </div>
      <div class="message" v-if="data.status == 5">
        <div class="title">订单评价</div>
        <el-form
          class="logistics_form"
          ref="logistics_form"
          inline
          label-position="left"
          label-width="120px"
        >
          <el-form-item label="评价码:" class="finish-audit-file">
            <span class="files">
              <div
                style="position: relative;display: flex;flex-direction: column;align-items: center;justify-content: center;"
              >
                <img
                  :src="$utils.picturePrefix(data.qrCode)"
                  style="margin-right: 0;"
                />
                <span style="line-height: 1.5;font-size: 12px;color: #999;">
                  客户扫码评价~
                </span>
              </div>
            </span>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 配件单详情 -->
    <div class="accessories_details evaluation" v-else id="install-details">
      <div class="logistics_info">
        <div class="status_list">
          <div class="status" v-for="(item, i) in list1" :key="i">
            <div class="item">
              <div class="text">
                <img :src="item.isArrive ? item.src_sel : item.src" alt="" />
                <p class="title">{{ item.title }}</p>
                <p class="date">{{ item.time }}</p>
              </div>
              <div>
                <img
                  :src="item.isArrive ? item.arrows_sel : item.arrows"
                  alt=""
                  v-show="i < list1.length - 1"
                />
              </div>
            </div>
          </div>
          <!-- <div class="top-right"> -->
          <!-- <div class="right-item" v-for="(item, i) in list1" :key="i">
              <div class="item">
                <img
                  :src="accessories_info.status == 1 ? item.src_sel : item.src"
                />
                <div
                  class="type"
                  :class="{ arrive: i < Number(accessories_info.status) - 1 }"
                >
                  {{ item.title }}
                </div>
                <div
                  class="time"
                  v-show="i < Number(accessories_info.status) - 1 && i == 0"
                >
                  {{ data.serviceTime }}
                </div>
                <div
                  class="time"
                  v-show="i < Number(accessories_info.status) - 1 && i == 1"
                >
                  {{ data.receivingTime }}
                </div>
                <div
                  class="time"
                  v-show="i < Number(accessories_info.status) - 1 && i == 2"
                >
                  {{ data.appointmentDate }}
                    {{ data.appointmentTimeS }} 至 {{ data.appointmentTimeE }}
                </div>
                <div
                  class="time"
                  v-show="i < Number(accessories_info.status) - 1 && i == 3"
                >
                  {{ data.completeTime }}
                </div>
                <div
                  class="time"
                  v-show="i < Number(accessories_info.status) - 1 && i == 4"
                >
                  {{ data.auditTime }}
                </div>
                <div
                  class="time"
                  v-show="i >= Number(accessories_info.status) - 1"
                ></div>
              </div>
              <div class="arrows arrows1" v-if="i < 2">
                <img
                  :src="
                    i + 1 < Number(accessories_info.status) - 1
                      ? item.arrows_sel
                      : item.arrows
                  "
                />
              </div>
            </div> -->
          <!-- </div> -->
        </div>
        <div class="not" v-if="accessories_info.status == 3">
          审核不通过原因
        </div>
        <el-input
          v-if="accessories_info.status == 3"
          v-model="accessories_info.aduitInfo"
          class="form-textarea"
          type="textarea"
          :rows="5"
          disabled
          placeholder=""
        ></el-input>
        <!-- <el-input type="textarea" v-model="mInfo" placeholder="发布留言"> -->
        <el-form
          ref="form"
          :model="accessories_info"
          class="totality"
          v-if="accessories_info.status != 3"
        >
          <el-form-item label="物流公司:">
            <el-input
              v-model="accessories_info.logisticsName"
              disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label="物流单号:">
            <el-input
              v-model="accessories_info.logisticsNum"
              disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label="第三方配件单号:">
            <el-input
              v-model="accessories_info.threeNum"
              disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="remarks" v-if="accessories_info.status != 3">发件备注:</div>
        <el-input
          v-if="accessories_info.status != 3"
          disabled="disabled"
          class="text"
          resize="none"
          type="textarea"
          size="medium"
          v-model="accessories_info.remarke"
          placeholder="发件备注"
        >
        </el-input>
        <div class="remarks" v-if="accessories_info.status != 3 && state != 1">
          凭证:
        </div>
        <img
          v-show="accessories_info.status != 3 && state != 1"
          v-for="(item, i) in accessories_info.accessoriesImg"
          :key="i"
          :src="$utils.picturePrefix(item)"
          alt=""
          style="
            width: 120px;
            height: 120px;
            border-radius: 10px;
            margin-right: 20px;
          "
        />
        <!-- <div class="remarks" v-if="accessories_info.status != 2 && state != 3">
          申请凭证:
        </div>
        <img
          v-show="accessories_info.status != 2 && state != 3"
          v-for="(item, i) in accessories_info.accessoriesImg"
          :key="i"
          :src="$utils.picturePrefix(item)"
          alt=""
          style="
            width: 120px;
            height: 120px;
            border-radius: 10px;
            margin-right: 20px;
          "
        /> -->
        <div class="remarks" v-if="accessories_info.status != 3">物流信息:</div>

        <el-timeline :reverse="reverse" v-if="accessories_info.status != 3">
          <el-timeline-item
            v-for="(activity, index) in getLogistics"
            :key="index"
            :timestamp="activity.time"
          >
            <p class="date">{{ activity.time }}</p>
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </div>

      <!-- 返件 -->
      <div
        class="information"
        v-if="
          this.accessories_info.oldReturn == 1 && accessories_info.status != 3
        "
      >
        <div class="rise">返件信息</div>
        <!-- <div>
          <p class="space">返件地址：</p>
          <el-input
            v-model="returnData.returnAddress"
            placeholder=""

            style="width: 250px"
          ></el-input>
          <p class="space">返件要求：</p>
          <el-input

            class="cause"
            type="textarea"
            maxlength="200"
            show-word-limit
            v-model="returnData.accessoriesDetail"
            placeholder=""
            style="width: 660px"
          ></el-input>
        </div> -->

        <el-form
          class="logistics_form"
          ref="logistics_form"
          inline
          label-position="left"
          label-width="120px"
          :model="returnData"
          :rules="return_rules"
        >
          <el-form-item label="返件地址:" style="width: 100%">
            <el-input
              disabled
              v-model="returnData.returnAddress"
              style="width: 676px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="返件要求:" style="width: 100%">
            <el-input
              disabled
              resize="none"
              v-model="returnData.accessoriesDetail"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="物流公司:"
            style="width: 38%;"
            prop="logisticsName"
          >
            <el-input
              v-model="returnData.logisticsName"
              :disabled="returnDisal"
              style="width: 250px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="物流单号:"
            style="width: 36%"
            prop="logisticsNum"
          >
            <el-input
              v-model="returnData.logisticsNum"
              :disabled="returnDisal"
              style="width: 250px;"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="第三方配件单号:">
            <el-input v-model="returnData.threeNum"></el-input>
          </el-form-item> -->
          <el-form-item label="发件备注:" style="width: 98%">
            <el-input
              resize="none"
              v-model="returnData.remarke"
              type="textarea"
              placeholder="请输入发件备注"
              :disabled="returnDisal"
            ></el-input>
          </el-form-item>
          <el-form-item label="凭证:" prop="accessoriesImg">
            <div v-show="returnDisal">
              <img
                v-for="(item, i) in returnData.accessoriesImg"
                :key="i"
                :src="item.showPath"
              />
            </div>
            <el-upload
              action="*"
              :http-request="uploadImg_oldreturn"
              list-type="picture-card"
              :on-remove="handleAvatarRemove_oldreturn"
              :before-upload="beforeAvatarUpload"
              accept="image/*"
              :file-list="filelist"
              v-show="!returnDisal"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>

        <div class="button_btn" v-show="!returnDisal">
          <el-button type="primary" @click="save_return_form"> 提交 </el-button>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <sign-it-operation ref="signItOperation"></sign-it-operation>
    <tip-modal :visible="visibleTip" @close="handleClose" @ok="signClosed">
      <span>确定签收吗？</span>
    </tip-modal>
    <in-service-operation ref="inServiceOperation"></in-service-operation>
    <in-service-appointment ref="inServiceAppointment"></in-service-appointment>
  </div>
</template>

<script>
// import Empty from "@/components/Empty";
import signItOperation from "../signIt/operation";
import inServiceOperation from "../inService/operation.vue";
import inServiceAppointment from "../inService/appointment.vue";
import TipModal from "@/components/TipModal";
import MyDialog from "./logistics/index.vue";
export default {
  components: {
    // Empty,
    signItOperation,
    TipModal,
    inServiceOperation,
    inServiceAppointment,
    MyDialog
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("配件数量不能为空"));
      } else {
        if (!isNaN(value)) {
          callback();
        } else {
          return callback(new Error("配件数量必须为数字"));
        }
      }
    };
    return {
      isReoder: "0",
      backWhy: "",
      visibleTip: false,
      tabType: "1",
      type: 1,
      show_accessories: false,
      accessories_info: {
        status: 1
      },
      getLogistics: [],
      messageId: null,
      replyInfo: "",
      mInfo: "",
      status: {
        1: "审核中",
        2: "通过",
        3: "失败"
      },
      state: "",
      status_color: {
        1: "#F56C6C",
        2: "#409EFF",
        3: "#F56C6C"
      },
      //  2待签收  3服务中  4已预约  5已完成  6已结算

      list: [
        {
          src: require("@/assets/img/install/img-install-details-1.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-1.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "待签收",
          time: "2021-08-26",
          isArrive: true,
          path: "/personal-center/install/signit"
        },
        {
          src: require("@/assets/img/install/img-install-details-2.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-2.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "服务中",
          time: "2021-08-26",
          isArrive: false,
          path: "/personal-center/install/inservice"
        },
        {
          src: require("@/assets/img/install/img-install-details-3.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-3.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "已预约",
          time: "2021-08-26",
          isArrive: false,
          path: "/personal-center/install/appointment"
        },
        {
          src: require("@/assets/img/install/img-install-details-4.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-4.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "已完成",
          time: "",
          isArrive: false,
          path: "/personal-center/install/complete"
        },
        {
          src: require("@/assets/img/install/img-install-details-5.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-5.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "已结算",
          time: "2021-08-26",
          isArrive: false,
          path: "/personal-center/install/settlement"
        }
      ],
      title: "同意",
      list1: [
        {
          src: require("@/assets/img/install/img-install-details-2.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-2.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "提交配件申请",
          time: "",
          isArrive: true,
          path: "/personal-center/install/inservice"
        },
        {
          src: require("@/assets/img/install/img-install-details-3.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-3.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "申请审核中",
          time: "",
          isArrive: false,
          path: "/personal-center/install/appointment"
        },
        {
          src: require("@/assets/img/install/img-install-details-4.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-4.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "同意",
          time: "",
          isArrive: false,
          path: "/personal-center/install/complete"
        }
      ],
      form: {},
      // 留言
      oid: null,
      dialogVisible: false,
      //  配件单
      accessories: {
        accessoriesName: "",
        accessoriesNum: "",
        accessoriesWhy: "",
        accessoriesImg: []
      },

      //  配件
      Return: {
        //  返件物流凭证
        accessoriesLogisticsImg: "",
        //  返件物流单号
        accessoriesLogisticsNum: ""
        //
      },
      // 返件信息
      returnData: {
        returnAddress: "",
        accessoriesDetail: "",
        logisticsName: "",
        logisticsNum: "",
        remarke: "",
        accessoriesImg: []
      },
      returnDisal: false,

      //  返件信息

      rules: {
        accessoriesName: [
          { required: true, message: "配件名称不能为空", trigger: "blur" }
        ],
        accessoriesNum: [{ validator: checkNum, trigger: "blur" }],
        accessoriesImg: [
          { required: true, message: "请您上传凭证", trigger: "blur" }
        ]
      },
      return_rules: {
        logisticsName: [
          { required: true, message: "物流名称不能为空", trigger: "blur" }
        ],
        logisticsNum: [
          { required: true, message: "物流单号不能为空", trigger: "blur" }
        ],
        accessoriesImg: [
          { required: true, message: "请您上传凭证", trigger: "blur" }
        ]
        // returnAddress: [
        //   { required: true, message: "返件地址不能为空", trigger: "blur" }
        // ],
        // accessoriesDetail: [
        //   { required: true, message: "返件要求不能为空", trigger: "blur" }
        // ]
      },

      personnel: [],
      data: {},
      reverse: true,
      activities: [],
      value: 0,
      // 配件类型
      title_list: [
        "配件名称",
        "配件数量",
        "申请原因",
        "审核状态"
        // {
        //   name: "配件名称",
        //   quantity: "配件数量",
        //   cause: "申请原因",
        //   audit: "审核状态"
        // }
      ],
      // 配件信息详情
      details_list: [],
      filelist: [],
      finishFilelist: [],
      evaluate: {},
      finishData: {
        auditFile: []
      },
      finishRules: {
        auditFile: [
          { required: true, message: "请您上传凭证", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  watch: {
    tabType(val) {
      if (val == 2) {
        this.query_accessoriesOrderList();
      }
    },
    "data.status": {
      deep: true,
      handler(val) {
        if (val != 4) {
          this.tabType = "2";
          this.query_accessoriesOrderList();
        }
      }
    }
  },
  mounted() {
    let type = this.$route.query.type;
    this.oid = this.$route.query.oid;
    this.type = Number(type);
    if (this.isReoder == 1) {
      this.backOrderDetail();
    } else {
      this.installOrderDetail();
    }

    this.query_OrderEvaluation();
  },
  //
  created() {
    this.isReoder = sessionStorage.getItem("isReoder");
  },
  methods: {
    /**
     * 配件上传图片
     */
    uploadImg(param) {
      let file = param.file;
      let form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .uploadImg(form)
        .then(res => {
          this.accessories.accessoriesImg.push({
            name: file.name,
            showPath: this.$utils.picturePrefix(res.data.filePath),
            path: res.data.filePath
          });
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    /**
     *  返件上传图片
     */
    uploadImg_oldreturn(param) {
      let file = param.file;
      let form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .uploadImg(form)
        .then(res => {
          this.returnData.accessoriesImg.push({
            name: file.name,
            showPath: this.$utils.picturePrefix(res.data.filePath),
            path: res.data.filePath
          });
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    /**
     *  返件完成图片
     */
    uploadImgFinishFile(param) {
      let file = param.file;
      let form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      console.log(file);
      this.$api
        .uploadOtherFile(form)
        .then(res => {
          this.finishData.auditFile.push({
            name: file.name,
            showPath: this.$utils.picturePrefix(res.data.filePath),
            path: res.data.filePath,
            type: file.type.indexOf("image/") != -1 ? 1 : 2
          });
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    /**
     *完成 删除图片
     */
    handleRemoveFinishFile(index) {
      this.finishData.auditFile.splice(index, 1);
      // this.finishData.auditFile = this.finishData.auditFile.filter(e => {
      //   return e.name != file.name;
      // });
    },
    /**
     * 配件删除图片
     */
    handleAvatarRemove(file) {
      this.accessories.accessoriesImg = this.accessories.accessoriesImg.filter(
        e => {
          return e.name != file.name;
        }
      );
    },
    /**
     *返件 删除图片
     */
    handleAvatarRemove_oldreturn(file) {
      this.returnData.accessoriesImg = this.accessories.accessoriesImg.filter(
        e => {
          return e.name != file.name;
        }
      );
    },
    /**
     * 上传前图片判断
     */
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },

    initInstallationOrder() {
      this.installOrderDetail();
    },
    installOrderDetail() {
      let params = { id: this.oid };
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .installOrderDetail(params)
        .then(res => {
          this.data = res.data;

          // 我的凭证
          if (this.data.auditFile) {
            this.data.auditFile = this.data.auditFile.split(",");
          }

          if (this.data.auditFile) {
            let auditFile = [];
            const checkFileType = suffix => {
              var type = 2;
              switch (suffix) {
                case "jpg":
                case "jpeg":
                case "png":
                case "bmp":
                case "gif":
                case "tif":
                case "pcx":
                case "tga":
                  type = 1;
                  break;
              }
              return type;
            };
            this.data.auditFile.map(item => {
              auditFile.push({
                showPath: this.$utils.picturePrefix(item),
                // path: preg_match("/.*(\.png|\.jpg|\.jpeg|\.gif)$/", item)
                type: checkFileType(
                  item.substring(item.lastIndexOf(".") + 1).toLowerCase()
                )
              });
            });

            this.finishData.auditFile = auditFile;
          }
          console.log("---", this.data.auditFile);

          // if (this.type > 3) {
          //   this.tabType = "2";
          //   this.query_accessoriesOrderList();
          // }
          if (res.data.orderCommentsList1) {
            res.data.orderCommentsList1 = this.$utils.moreOrTwoArr(
              res.data.orderCommentsList1
            );
          }
          if (res.data.orderCommentsList2) {
            res.data.orderCommentsList2 = this.$utils.moreOrTwoArr(
              res.data.orderCommentsList2
            );
          }
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 拒收
     */
    handleRefuse() {
      this.$refs.signItOperation.init(this.data.id);
    },
    handleClose() {
      this.visibleTip = false;
    },
    openTipModal() {
      this.visibleTip = true;
    },
    /**
     * 签收订单
     */
    signClosed() {
      let params = {
        id: this.oid,
        type: 1
      };
      const loading = this.$loading({
        lock: true,
        text: "签收中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .postInstallOrderReceiving(params)
        .then(() => {
          this.$message({
            message: "恭喜您，签收成功！",
            type: "success"
          });
          this.visibleTip = false;
          this.initInstallationOrder();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    /**
     * 取消订单
     */
    handleCancel() {
      this.$refs.inServiceOperation.init(this.oid);
    },
    /**
     * 预约订单
     */
    handleAppointment() {
      this.$refs.inServiceAppointment.init(this.oid);
    },
    /**
     * 获取物流信息
     */
    query_getLogistics() {
      this.$api
        .getLogistics({
          logisticsNumber: this.accessories_info.logisticsNum
          // logisticsNumber: 12345678
        })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            this.getLogistics = data;
          }
        });
    },
    installationOrderComplete() {
      let img = [];
      this.finishData.auditFile.forEach(e => {
        img.push(e.path);
      });
      console.log(this.finishData.auditFile);
      const loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .installationOrderComplete({
          auditFile: img.join(","),
          id: this.oid
        })
        .then(() => {
          this.installOrderDetail();
          // this.messageId = null;
          // this.replyInfo = "";
          // this.mInfo = "";
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    /**
     * 提交派件
     */
    accessories_save() {
      this.$refs.installOrderMassage.validate(valid => {
        if (valid) {
          let img = [];
          this.accessories.accessoriesImg.forEach(e => {
            img.push(e.path);
          });
          this.$api
            .installationOrderAccessories({
              installationId: Number(this.data.id),
              type: 1,
              backOrderId: this.data.backOrderId,
              customerId: this.data.customerId,
              accessoriesImg: img.join(","),
              accessoriesNum: Number(this.accessories.accessoriesNum),
              accessoriesWhy: this.accessories.accessoriesWhy,
              accessoriesName: this.accessories.accessoriesName
            })
            .then(res => {
              this.accessories.accessoriesImg = [];
              this.accessories.accessoriesNum = " ";
              this.accessories.accessoriesName = "";
              this.accessories.accessoriesWhy = "";
              this.filelist = [];
              this.finishFilelist = [];
              let { code, msg } = res;
              if (code == 200) {
                this.$message({
                  type: "success",
                  message: msg,
                  duration: 2000
                });
              }
            });
        }
      });
    },

    installOrderMassage(id, type, info) {
      this.$refs.installOrderMassage.validate(valid => {
        if (valid) {
          this.installOrderComment(id, type, info);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    mountings_status(id, i) {
      console.log(id, i);
    },

    installOrderComment(id, type, info) {
      if (info == "") {
        if (id == 0) {
          this.$message.error("留言内容不可以为空！");
        } else {
          this.$message.error("回复内容不可以为空！");
        }

        return;
      }
      let params = {
        accessoriesName: "",
        accessoriesNum: "",
        customerId: this.userId,
        info: info,
        orderId: this.oid,
        pid: id,
        type: type
      };
      if (type == 2) {
        params = {
          ...this.accessories,
          customerId: this.userId,
          info: info,
          orderId: this.oid,
          pid: id,
          type: type
        };
      }
      const loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .installOrderComment(params)
        .then(() => {
          this.initInstallationOrder();
          this.messageId = null;
          this.replyInfo = "";
          this.mInfo = "";
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    /**
     *  获取配件单列表
     */
    query_accessoriesOrderList() {
      this.$api
        .accessoriesOrderList({
          customerId: this.data.customerId,
          num: 10000,
          page: 1,
          installationId: this.oid
        })
        .then(res => {
          let { data, code } = res;
          if (code == 200) {
            this.details_list = data.records;
          }
        });
    },
    /**
     * 获取配件详情
     */
    cellClick(row) {
      this.$router.push(
        `/personal-center/install/accessories-details?id=${row.id}&type=${this.type}&oid=${this.oid}&dtype=0`
      );
      // this.state = row.status;
      // this.$api
      //   .accessoriesOrderDetail({
      //     id: row.id
      //   })
      //   .then(res => {
      //     let { code, data } = res;
      //     if (code == 200) {
      //       console.log("data", data);
      //       this.accessories_info = data;
      //       // // this.returnData
      //       this.query_getLogistics();
      //       this.returnData.number = this.accessories_info.number;
      //       this.returnData.id = this.accessories_info.id;
      //       this.accessories_info.accessoriesImg = this.accessories_info.accessoriesImg.split(
      //         ","
      //       );
      //       this.returnData.returnAddress =
      //         this.accessories_info.returnAddress &&
      //         this.accessories_info.returnAddress.address;
      //       this.returnData.accessoriesDetail = this.accessories_info.accessoriesDetail;
      //       this.list1[0].time = this.accessories_info.applyTime;
      //       if (this.accessories_info.status == 1) {
      //         this.list1[1].isArrive = true;
      //         this.list1[1].time = this.accessories_info.applyTime;
      //       } else if (
      //         this.accessories_info.status == 2 ||
      //         this.accessories_info.status == 3
      //       ) {
      //         this.list1[1].isArrive = true;
      //         this.list1[1].time = this.accessories_info.applyTime;
      //         this.list1[2].isArrive = true;
      //         this.list1[2].time = this.accessories_info.aduitTime;
      //       }
      //       if (this.accessories_info.status == 3) {
      //         this.list1[2].title = "不同意";
      //       }
      //       let accessoriesImg = [];
      //       let accessoriesLogisticsImg = this.accessories_info.accessoriesLogisticsImg.split(
      //         ","
      //       );
      //       accessoriesLogisticsImg.map(item => {
      //         accessoriesImg.push({
      //           name: "none",
      //           showPath: this.$utils.picturePrefix(item),
      //           path: item
      //         });
      //       });
      //       this.returnData.logisticsName = this.accessories_info.accessoriesLogisticsName;
      //       this.returnData.logisticsNum = this.accessories_info.accessoriesLogisticsNum;
      //       this.returnData.remarke = this.accessories_info.accessoriesLogisticsRemarke;
      //       this.returnData.accessoriesImg = accessoriesImg;
      //       this.returnDisal =
      //         this.accessories_info.accessoriesLogisticsNum != "";

      //       console.log("this.returnData, ", this.returnData.accessoriesImg);

      //       // else if (this.accessories_info.status == 3) {
      //       //   // this.list1.forEach((e, i) => {
      //       //   //   e.isArrive = true;
      //       //   //   if (i == 2) {
      //       //   //     e.time = this.accessories_info.aduitTime;
      //       //   //   }
      //       //   // });
      //       //   // this.list1[1].title = "不同意";
      //       // }
      //     }
      //   });

      // this.show_accessories = true;
      // this.$nextTick(() => {
      //   document.getElementById("app").scrollTop = 0;
      // });
    },
    /**
     *   提交返件表单
     */
    save_return_form() {
      this.$refs.logistics_form.validate(valid => {
        if (valid) {
          let img = [];
          if (Array.isArray(this.returnData.accessoriesImg)) {
            this.returnData.accessoriesImg.forEach(e => {
              img.push(e.path);
            });

            this.returnData.accessoriesImg = img.join(",");
          }

          this.$api
            .installationOrderReturn({
              number: this.returnData.number,
              id: this.returnData.id,
              // 返件物流单号
              accessoriesLogisticsNum: this.returnData.logisticsNum,
              //  返件物流名称
              accessoriesLogisticsName: this.returnData.logisticsName,
              //  返件物流凭证
              accessoriesLogisticsImg: this.returnData.accessoriesImg,
              // 返件物流备注
              accessoriesLogisticsRemarke: this.returnData.remarke
            })
            .then(res => {
              let { code, msg } = res;
              if (code == 200) {
                this.$message({
                  type: "success",
                  message: msg,
                  duration: 2000
                });
                this.returnDisal = true;
                this.installOrderDetail();
                // sessionStorage.setItem('returnData',this.returnData)
                // for (let k in this.returnData) {
                //   this.returnData[k] = "";
                // }
                // this.filelist = [];
              } else {
                this.$message({
                  type: "error",
                  message: msg,
                  duration: 2000
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: "处理出错,请稍后再试!",
                duration: 2000
              });
            });
        }
      });
    },

    /**
     *  获取评价列表
     */
    query_OrderEvaluation() {
      this.$api.getInstallationOrderDetail(this.oid).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.evaluate = data;
        }
        console.log("获取评价列表", res);
      });
    },

    // 获取返单详情接口
    backOrderDetail() {
      this.$api
        .backOrderDetail({
          // id: this.oid,
          id: 22
        })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            console.log("22", data);
            this.backWhy = data.backWhy;
            this.data = data.installationOrder;
            if (this.data.auditFile) {
              this.data.auditFile = this.data.auditFile.split(",");
            }
          }
        });
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
